// 宛先等のユーザの選択のためのダイアログ // ロールによって表示内容を切り替える

<template lang="pug">
el-dialog.user-select-dialog(
  title='対象者の選択',
  width='60%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open',
  append-to-body
)
  article

    header

      .operations

        .buttons
          el-button(size='small', @click='selectAll') すべて選択
          el-button(size='small', @click='selectNothing') すべて解除

        .status
          span {{ targetNum }}
          | 名中
          span {{ selectedNum }}
          | 名選択
      
      .filters(v-if='role !== "student"')
        p 該当する施設類型の園を選択:
        .filter-buttons
          el-button(
            type='text',
            v-for='item in usedOrganizationFacilityTypes',
            :key='item.value',
            @click='checkByOrganizationFacilityTypes(item.value)'
          ) {{ item.label }}

    el-table(
      :data='members',
      height='500'
    )
      el-table-column(
        label='選択',
        width='50',
        align='center'
      )
        template(slot-scope='scope')
          el-checkbox(:value='isSelected(scope.row.userId)', @change='clickCheckbox(scope.row.userId)')
      template(v-if='role === "student"')
        el-table-column(
          prop='loginName',
          label='受講者番号'
        )
        el-table-column(
          label='名前'
        )
          template(slot-scope='scope')
            span {{ `${scope.row.firstName} ${scope.row.lastName}` }}
        el-table-column(
          prop='organizationName',
          label='幼稚園名'
        )
      template(v-else)
        el-table-column(
          prop='organizationNumber',
          label='園番号',
          width='120'
        )
        el-table-column(
          prop='organizationRegion',
          label='地区',
          width='120'
        )
        el-table-column(
          label='園名'
        )
          template(slot-scope='scope')
            .name {{ scope.row.organizationName }}
            .facility-type.text-xs {{ scope.row.organizationFacilityTypeName ? `(${scope.row.organizationFacilityTypeName})` : '' }}

    footer
      el-button(type='primary', :disabled='selectedNum === 0', @click='close(true)') 決定
      el-button(@click='close(false)') キャンセル
</template>

<script>
import xor from 'lodash.xor'
import isNil from 'lodash.isnil'
import union from 'lodash.union'
import getGroupMembersApi from '@/api/admin/get-group-members'

export default {
  name: 'UserSelectDialog',

  data() {
    return {
      members: [],
      selectedUserIds: null,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userGroupId: {
      type: Number,
    },
    // 選択されたユーザ
    value: {
      type: Array,
    },
  },

  computed: {
    // グループに所属するユーザのロール
    role() {
      if (this.members.length === 0) return null
      // 一番最初のユーザのロールを全体のロールとする
      else return this.members[0].role
    },
    // 対象者全体の人数
    targetNum() {
      return this.members.length
    },
    // 選択されている人数
    selectedNum() {
      return this.selectedUserIds ? this.selectedUserIds.length : this.targetNum
    },
    // 使用されている施設類型の一覧を返す
    // 園専用
    usedOrganizationFacilityTypes() {
      if (this.role === 'student') return []
      const facilityTypeIdAndName = this.members.reduce((prev, curr) => {
        if (!isNil(curr.organizationFacilityTypeId)) {
          prev[curr.organizationFacilityTypeId] = curr.organizationFacilityTypeName
        }
        return prev
      }, {})
      const facilityTypePairs = Object.keys(facilityTypeIdAndName)
        .map((item) => ({
          label: facilityTypeIdAndName[item],
          value: parseInt(item, 10),
        }))
        .sort((a, b) => b.key - a.key)
      facilityTypePairs.push({
        label: '未設定の園',
        value: null,
      })
      return facilityTypePairs
    },
  },

  methods: {
    async open() {
      const response = await getGroupMembersApi(this.userGroupId)
      if (!response.ok) return
      this.members = response.payload.items
      if (this.value) this.selectedUserIds = [...this.value]
      else this.selectedUserIds = null
    },

    isSelected(userId) {
      // nullならすべてtrue
      if (!this.selectedUserIds) return true
      else return this.selectedUserIds.indexOf(userId) !== -1
    },

    clickCheckbox(userId) {
      // nullなら全要素追加
      if (!this.selectedUserIds) this.selectedUserIds = this.members.map((item) => item.userId)
      this.selectedUserIds = xor(this.selectedUserIds, [userId])
    },

    selectAll() {
      this.selectedUserIds = null
    },

    selectNothing() {
      this.selectedUserIds = []
    },

    checkByOrganizationFacilityTypes(facilityTypeId) {
      // null = 全選択状態なら、一度クリアする
      if (!this.selectedUserIds) this.selectNothing()
      // そのfacilityTypeIdのメンバーを列挙
      const newUserIds = this.members
        .filter((item) => item.organizationFacilityTypeId === facilityTypeId)
        .map((item) => item.userId)
      this.selectedUserIds = union(this.selectedUserIds, newUserIds)
    },

    close(success) {
      if (success) this.$emit('input', this.selectedUserIds)
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.user-select-dialog
  article

    header
      .operations
        overflow: hidden
        .buttons
          float: left
        .status
          float: right
          span
            font-size: 1.2rem
            color: $grey-dark
            margin: 0 0.2rem
            font-weight: bold
      .filters
        margin-top: 1rem
        .filter-buttons > *
          margin: 0 1rem
    footer
      margin-top: 1rem
</style>
