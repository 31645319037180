// 特定のグループに所属するメンバーの取得

import api from '../api'

const path = '/admin/user-groups/{id}/members'

export default (userGroupId, all = false) => {
  let url = path.replace('{id}', userGroupId)
  if (all) url += '?all=true'
  return api({
    url,
    auth: true,
  })
}
