// メッセージの更新

import api from '../api'

const path = '/admin/messages/{id}'

export default (messageId, { userGroupId, recipients = null, sendMail, title, body }) => {
  const url = path.replace('{id}', messageId)
  return api({
    method: 'put',
    url,
    params: {
      userGroupId,
      recipients,
      sendMail,
      title,
      body,
    },
    auth: true,
  })
}
