// メッセージの送信

import api from '../api'

const path = '/admin/messages/{id}/send'

export default (messageId) => {
  const url = path.replace('{id}', messageId)
  return api({
    method: 'post',
    url,
    auth: true,
  })
}
