// グループの一覧を取得

import api from '../api'

const path = '/admin/user-groups'

export default (acceptableRole = null) => {
  let url = path
  if (acceptableRole) {
    url += `?acceptableRole=${acceptableRole}`
  }
  return api({
    url,
    auth: true,
  })
}
