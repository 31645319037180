// メッセージ編集ダイアログ

<template lang="pug">
el-dialog.message-modify-dialog(
  title='メッセージの作成と編集',
  width='80%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open',
  append-to-body
)
  article(v-if='ready')
    el-form(:model='form', :rules='rules', ref='form')
      el-form-item(label='宛先', prop='userGroupId')
        el-select(
          v-model='form.userGroupId',
          @change='form.recipients = null'
        )
          el-option(
            v-for='item in groups',
            :key='item.id',
            :label='item.name',
            :value='item.id'
          )
        template(v-if='form.userGroupId')
          span.conjunction のうちの
          el-button(@click='selectUsers') {{ recipients }}
      el-form-item(label='Eメール送信')
        el-switch(v-model='form.sendMail')
      el-form-item(label='タイトル', prop='title')
        el-input(v-model='form.title')
      el-form-item(label='本文', prop='body')
        el-input(v-model='form.body', type='textarea', :rows='8')
      el-form-item
        el-button(@click='send', type='primary') 送信
        el-button(@click='createOrUpdate', type='success') 下書き保存
        el-button(@click='close(false)') キャンセル

  user-select-dialog(
    :visible.sync='dialogVisible',
    :userGroupId='form.userGroupId',
    v-model='form.recipients'
  )
</template>

<script>
import getGroupListApi from '@/api/admin/get-group-list'
import createMessageApi from '@/api/admin/create-message'
import updateMessageApi from '@/api/admin/update-message'
import getMessageApi from '@/api/admin/get-message'
import sendMessageApi from '@/api/admin/send-message'

import UserSelectDialog from './user-select-dialog'

export default {
  name: 'MessageModifyDialog',

  components: {
    UserSelectDialog,
  },

  data() {
    return {
      form: {
        userGroupId: null,
        recipients: null,
        sendMail: true,
        title: '',
        body: '',
      },
      rules: {
        userGroupId: [{ required: true, message: '宛先を選択してください。' }],
        title: [{ required: true, message: 'タイトルを入力してください。' }],
        body: [{ required: true, message: '本文を入力してください。' }],
      },
      // グループの候補
      groups: [],
      dialogVisible: false,
      // 準備ができたかどうか
      ready: false,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    messageId: {
      type: Number,
    },
    // 宛先の初期設定
    initialUserGroupId: {
      type: Number,
      default: null,
    },
    initialRecipients: {
      type: Array,
      default: null,
    },
  },

  computed: {
    recipients() {
      return this.form.recipients && this.form.recipients.length
        ? `${this.form.recipients.length} 名`
        : '全員'
    },
  },

  methods: {
    async init() {
      this.ready = false
      if (this.$refs.form) this.$refs.form.resetFields()
      let response = await getGroupListApi()
      if (!response.ok) return
      // アプリユーザ(志望者)は除去しておく
      this.groups = response.payload.items.filter((item) => item.acceptableRole !== 'applicant')
      if (this.messageId) {
        response = await getMessageApi(this.messageId)
        if (!response.ok) return
        this.form.userGroupId = response.payload.userGroupId
        this.form.recipients = response.payload.recipients
        this.form.sendMail = response.payload.sendMail
        this.form.title = response.payload.title
        this.form.body = response.payload.body
      } else {
        // プロパティで初期設定可能
        this.form.userGroupId = this.initialUserGroupId
        this.form.recipients = this.initialRecipients
        this.form.sendMail = true
        this.form.title = ''
        this.form.body = ''
      }
      this.ready = true
    },
    open() {
      this.init()
    },
    close(success, send = false) {
      if (success) {
        if (send) this.$emit('send-message')
        else this.$emit('update-message')
      }
      this.$emit('update:visible', false)
    },
    // 送信先のユーザを個別指定する
    selectUsers() {
      this.dialogVisible = true
    },
    // 新規作成または内容更新
    async _createOrUpdate() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return null
      }
      // APIアクセス
      let response
      if (this.messageId) {
        response = await updateMessageApi(this.messageId, this.form)
      } else {
        response = await createMessageApi(this.form)
      }
      if (!response.ok) {
        this.$alert('メッセージの保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return null
      }
      // 更新の場合は、this.messageIdから、
      // 新規の場合はレスポンスからIDを取得
      return this.messageId || response.payload.id
    },
    async createOrUpdate() {
      if (await this._createOrUpdate()) this.close(true)
    },
    // 送信。実際は保存して送信
    async send() {
      const messageId = await this._createOrUpdate()
      if (!messageId) return
      const response = await sendMessageApi(messageId)
      if (!response.ok) {
        this.$alert('メッセージの送信に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      this.close(true, true)
    },
  },
}
</script>

<style lang="sass" scoped>
.message-modify-dialog
  article
    .conjunction
      margin: 0 1rem
</style>
