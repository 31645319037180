// メッセージの作成

import api from '../api'

const path = '/admin/messages'

export default ({ userGroupId, recipients = null, sendMail, title, body }) => {
  return api({
    method: 'post',
    url: path,
    params: {
      userGroupId,
      recipients,
      sendMail,
      title,
      body,
    },
    auth: true,
  })
}
